$(document).ready(function(){

  const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  if(isTouchDevice() === false){
    var panels = [];
    var allEls = document.querySelectorAll(".project");
    for (var i = 0; i < allEls.length; i++) {
      var sb = new ScrollBooster({
        viewport: allEls[i],
        content: allEls[i].querySelector('.panels'),
        scrollMode: "native"
      });
      panels.push({id:allEls[i].id,sb:sb});
    }
    $('.project').mouseleave(function(){
      var sp = panels.find(element => element.id == this.id);
      sp.sb.scrollTo({ x: 0, y: 0 });
    })
  }

  if(isTouchDevice() === true){
    $('.project').on({ 'touchstart' : function(){
        $(".list").find(".project").not($(this)).animate({scrollLeft: 0}, 800);
      }
    });
  }




});